<template lang="pug">
  .generalBackground
    Header
    b-container.p-5(fluid="true")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.install icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.install) Install the Tygron platform
          b-collapse.collapsed(id="install")
            p The Edible City Game is built on the Tygron platform. Therefore, the first step is to install the software in your computer. Before installing it, please, be sure that your computer fulfil&nbsp;
              a(href="https://support.tygron.com/wiki/Requirements") the hardware requirements.
            p Once it has been checked, the installer can be download
              a(href="https://engine.tygron.com/")  here.
            b-img.m-3(src="../images/tygron_engine.png" center thumbnail)
            p On the site, it should appear the installer for our operating system. Once downloaded, just execute the file and follow the instructions on the screen.
            p.more-info
              a(href="https://support.tygron.com/wiki/Install") More information
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.start icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.start) Start a game session
          b-collapse.collapsed(id="start")
            p The first step is executing Tygron and login with the credentials. Click&nbsp;
              //b-button(v-b-modal.credentials variant="link") here
              a.link(v-b-modal.credentials) here
              span  to get the credentials.
            p Then, click on "Single user", then "Start new session" and choose {{sessionName}}. In case you wanted to reopen an old session, click on "Load saved session" and pick the date of the saved session.
            b-img.m-3(src="../images/start-1.png" thumbnail center height="200")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.control icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.control) Control menu
          b-collapse.collapsed(id="control")
            p While playing, a control menu can be opened using "ESC" key on the keyboard. This will open a menu with ther following options:
            ul
              li Settings: Set the volume and quality of graphics.
              li Credits: Watch the game credits
              li Exit: Close the session
              li Save: Save the session to continue later.
              li Export history: Download an excel file with information on the actions carried out during the session.
            b-img.m-3(src="../images/control-session.png" thumbnail center height="200")
      b-row
              b-col(cols="0")
                h5(class="coll-button")
                  b-icon(v-b-toggle.interface icon="chevron-up" size="sm")
              b-col()
                h5(class="coll-button")
                  a(type="button" v-b-toggle.interface) Game interface
                b-collapse.collapsed(id="interface")
                  p The game interface mainly consists on eight parts:
                  ol
                    li A 3D-model of the city in ther centre of the screen where the city is visualized along with the actions to modify it.
                    li The actions menu on the left. It shows the actions that can be done on the city (build, demolish, undo...).
                    li The character we are playing with on the top left corner (only relevant in multiplayer sessions).
                    li Session time and the scenario we are visualizing on the rigth of the player info. The original is the base scenario and the planned is the scenario with the changes made during the session.
                    li Indicators on the top of the screen. They are the indicators calculated for the city model. Click on an indicator to get detailed information as well as the achievement of the target.
                    li Progress bar on the top right corner. It is a weighted sum of the indicators' scores.
                    li Navigation menu under the progress bar. It allows to pan the 3D model. The mouse and the keyboard can be used as well.
                    li Addition information menu on the rigth. The main part are the overlays, which are layers of information that can be visualized over the 3D-model to get specific information, such as NO2 emissions, distance to green...).
                  b-img.m-3(src="../images/environment.png" thumbnail center height="200")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.budget icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.budget) Budget
          b-collapse.collapsed(id="budget")
            p Budget is one of the main constraints of urban planning. If the budget is in red numbers (you spent more than you had), the scores goes down to zero. The only option then is undo some actions until reverse the negative values in the budget.
            p Click on "budget" indicator to see the financial report of the session.
            b-img.m-3(src="../images/budget.png" thumbnail center)
            p.more-info
              a(href="https://support.tygron.com/wiki/Financial_system") More information
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.actions1 icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.actions1) Actions
          b-collapse.collapsed(id="actions1")
            p The actions are on the left menu and they are directly executed on the 3D-model. Below, there are the available actions in a single-player session:
            h5 Construct
            p It allows to construct in empty plots. In multiplayer sessions it is necessary, in addition, to be the land owner. You can find the available elements to construct and the realated information clicking on the question mark, which opens this&nbsp;
              a(href="https://ediblecitygame.icradev.cat/") panel.
            b-img.action(src="../images/actions/ecs.png" alt="ECS comunitàries")
            b-img.action(src="../images/actions/prof_ecs.png")
            b-img.action(src="../images/actions/park.png")
            b-img.action(src="../images/actions/social-housing.png")

            h5 Upgrades
            p Upgrades are used to improve an existing element. For instance, building a garden on a roof. Click on the question mark to get information of all avaialable upgrades. Once the panel is open, navigate to <a href="https://ediblecitygame.icradev.cat/upgrades.php">Upgrades.</a> tab.
            b-img.action(src="../images/actions/rooftop.png")
            b-img.action(src="../images/actions/fruit-trees.png")
            b-img.action(src="../images/actions/solar-panels.png")
            b-img.action(src="../images/actions/street.png")
            h5 Revert
            p It undoes actions executed during the session. When an action is undone, the cost is also restored to the budget.
            b-img.action(src="../images/actions/revert.png")
            h5 Demolish
            p It allows to remove any element in the city for, then, constructing a new one on the same plot.
            b-img.action(src="../images/actions/demolish.png")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.actions2 icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.actions2) Running an action
          b-collapse.collapsed(id="actions2")
            p Once the action selected, the available plots for that action are highlighted. Then, the area where to execute the action must be drawn on the 3D-model. There are several ways of drawing an area:
            ul
              li Using a grid by selecting the cells
              li Drawing a line with a specific width
                b  &#8592; this is probably the easier option
              li Drawing a circle
              li Selecting the whole plot
            b-img.m-3(src="../images/draw-action.png" thumbnail center)
            p.text-center By modifying the "width" parameter, the drawing precision can be adjusted.
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.indicators icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.indicators) Indicators
          b-collapse.collapsed(id="indicators")
            p Each executed action has an specific impact on the city indicators. Before confirming the action, the expected impact can be seen.
            p Click on the name of an indicator to open its panel that contains further information:
            ul
              li An explanation about what is measured by the indicator
              li The score in each neighbourhood
              li The "to do" value to reach the target set in the game
              li Some advise on how to improve the indicator's score
            b-img.m-3(src="../images/indicator.png" thumbnail center)
            //p.mt-5 The session's score is the weighted sum of all indicators. Click on the progress bar on the top right corner to see it.

    b-modal(id="credentials" title="Tygron credentials" ok-only)
      b-img.m-3(src="../images/credentials.png" center)

</template>

<script>
import Header from '@/components/header.vue';

export default {
  name: 'Ajuda',
  components: {
    Header
  },
  computed: {
    sessionName(){
      if (window.location.host.includes("sfl")) return "edible_sfl"
      if (window.location.host.includes("spg")) return "edible_spg"
      return "the session you are interested in"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .b-icon.bi.collapsed {
    transform: rotate(180deg);
  }

  img {
    max-height: 600px;
  }

  .more-info {
    text-align:right;
  }

  .action {
    height: 50px;
    margin-bottom: 1em;
  }

  .link{
    cursor: pointer;
    color:  #3848fc ;
  }
  .link:hover{
    text-decoration: underline;
    color: #0056B3;
  }
</style>
