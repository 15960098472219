<template lang="pug">
  .registrar.generalBackground
    div()
      h3 What do want to do?
      b-form-group.m-4
        b-form-checkbox(
          v-model="partida"
          type="checkbox"
          ) Register my score
        b-form-checkbox(
          v-model="error"
          type="checkbox"
          ) Report an error or suggest an improvement
      b-form-group(
          v-if="partida || error"
          id="name-group"
          label="Write your name or nickname:"
          label-for="name"
          description="Your name will only bu used to show the classification"
        )
        b-form-input(
            id="name"
            v-model="form.name"
            required
            )
      div(v-if="partida")
        br

        b-form-group(
            id="excel-group"
            label="Upload the excel with the session history:"
            label-for="excel"
            description='To download it from the game, press "ESC" and click on "Export action history"'
            )
          b-form-file(
              id="excel"
              v-model="excel"
              accept=".xlsx, .xls"
              ref="excelRef"
              )
        br
        //  Perception survey
        h6 Please, answer a few questions about your experience with the Edible City Game:
        p To what extent do you agree with the following sentences?
        div.ml-4(v-for="likert in likerts")
          b-form-group(
            id="likert-sentences",
            :label="likert",
            :label-for="likert + '-answer'"
          )
            b-form-radio-group(
              :id="likert + '-answer'"
              :options ="[{text:'Totally disagree', value:1},2,3,4,5,6,{text:'Totally agree', value:7}]"
              v-model="perceptionAnswers[likert]"
            )
          br
        b-form-group.mt-4(
          id="duration"
          label="The duration of the game was"
          label-for="duration-answer"
        )
          b-form-radio-group.ml-4(
            id="duration-answer"
            v-model="perceptionAnswers['duration']"
            :options="[{text:'Too short', value:1}, 2, 3, 4, 5, 6, {text:'Too long', value:7}]"
          )
        b-form-group.mt-5(
          id="gamer"
          label="Are you used to play videogames"
          label-for="gamer-answer"
        )
          b-form-radio-group.ml-4(
            id="gamer-answer"
            v-model="perceptionAnswers['gamer']"
            :options="[{text:'Nothing', value:1}, 2, 3, 4, 5, 6, {text:'Expert', value:7}]"
          )
        b-form-group.mt-5(
          id="expertise"
          label="Are you an expert in any of the following topics?"
          label-for="expertise-answer"
        )
          b-form-checkbox-group.ml-4(
            id="expertise-answer"
            v-model="perceptionAnswers['expertise']"
            :options="['Urban planner', 'Urban agriculture', 'Nature-based solutions']"
          )
        b-form-group.mt-5(
          id="gender"
          label="To which gender identity do you most identify?"
          label-for="gender-answer"
        )
        b-col.mb-3(cols="5")
          b-form-select.ml-4(
            id="gender-answer"
            v-model="perceptionAnswers['gender']"
            :options="['Female', 'Male', 'No binary', 'Others', 'Prefer no to answer']"
          )
        b-form-group.mt-5(
          id="age"
          label="What is your age?"
          label-for="age-answer"
        )
        b-col.mb-3(cols="5")
          b-form-select.ml-4(
            id="age-answer"
            v-model="perceptionAnswers['age']"
            :options="['18-30', '30-45', '45-60', '60-75', '> 75']"
          )
        b-form-group.mt-5(
          id="education"
          label="What is the highest educational level you obtained?"
          label-for="education-answer"
        )
        b-col.mb-3(cols="5")
          b-form-select.ml-4(
            id="education-answer"
            v-model="perceptionAnswers['education']"
            :options="['I do not have studies', 'Primary school', 'High school', 'Higher education', 'Doctorate']"
          )
        b-form-group.mt-5(
          id="residence"
          :label="'Do you live in ' + editionNameFull() + '?'"
          label-for="residence-answer"
        )
        b-form-radio-group.ml-4(
          id="residence_answer"
          v-model="perceptionAnswers['residence']"
          :options="['Yes', 'No']"
        )
        div(v-if="perceptionAnswers['residence'] === 'Yes'")
          b-form-input.m-4(
            id="neighbourhood"
            v-model="perceptionAnswers['neighbourhood']"
            placeholder="In which neighbourhood?"
          )

      // error reporting
      b-form-group(
        v-if="error"
        id="error-group"
        label="Describe the error you found or suggest an improvement:"
        label-for="error"
        )
        b-form-textarea(
          id="error"
          v-model="form.error"
          rows="6"
          )
      br(v-if="error")
      b-form-group(
        v-show="error && !hasImage"
        id="image-group"
        label="If you want, you can upload a screenshot:"
        label-for="image"
        )
        b-form-file(
          id="image"
          v-model="image"
          accept=".jpg, .png, .tif, .tiff, .gif"
          ref = "imageRef"
          )
      br(v-if="error && !hasImage")
      b-button(v-if="error && hasImage" variant="danger" class="ml-3" @click="clearImage") Clear image
      br(v-if="error && !hasImage")
      b-img(v-if="error && hasImage" :src="imageSrc" class="mb-3" fluid block rounded thumbnail)
      p(v-if="errorSubmit") {{errorSubmit}}
      b-button.m-3(v-if="partida || error" variant="outline-primary" @click="submit"
        ) Submit

</template>

<script>
  const base64Encode = data =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  import axios from 'axios';

  export default {
    name: 'Registrar',
    data: function(){
      return {
        form: {
          name: null,
          excel: null,
          error: null,
          image: null
        },
        partida: true,
        error: false,
        image: null,
        imageSrc: null,
        excel: null,
        sent: false,
        errorSubmit: false,
        likerts: [
          "I make the most of my time playing with the Edible City Game",
          "I improved my knowledge about the role of ECSs in the urban challenges",
          //"I better understood the interests of other stakeholders in the city planning",
          "I consider myself very aware of sustainability concerns",
          "The game instructions were very clear",
          "The game was really playful",
          "The interaction with the other players was stimulating",
          //"My actions during the game depended on the other players’ action",
          //"I influenced the other players’ action",
          //"The players pursued a common goal",
          "I felt comfortable playing the game",
          "The game fulfilled my expectations"
        ],
        perceptionAnswers: {}
      }
    },
    computed: {
      hasImage() {
        return !!this.image;
      }
    },
    watch: {
      image(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue) {
            base64Encode(newValue)
              .then(value => {
                this.imageSrc = value;
              })
              .catch(() => {
                this.imageSrc = null;
              });
          } else {
            this.imageSrc = null;
          }
        }
      }
    },
    methods: {
      clearImage() {
        this.image = null;
      },
      async submit(){
        this.errorSubmit = false;
        if (!this.partida){
          // this.form.score = 0;
          this.excel = null;
        }
        if (!this.error){
          this.form.error = null;
          this.image = null;
        }
        if (!this.formValidated()){
          this.errorSubmit = "Mandatory fields must be filled";
          return
        }
        if (this.excel) {
          this.form.excel = await this.uploadExcel()
          if (this.form.excel.startsWith("403")) {
            this.errorSubmit = "The excel file is not in the correct format";
            return
          }
          else if (this.form.excel.startsWith("500")) {
            this.errorSubmit = "Server error. Try again later";
            return
          }
        }
        if (this.image) {
          this.form.image = await this.uploadImage()
          if (this.form.image.startsWith("403")) {
            this.errorSubmit = "The screenshot must be in format jpg or png";
            return
          }
          else if (this.form.image.startsWith("500")) {
            this.errorSubmit = "Server error. Try again later";
            return
          }
        }


        let _this = this
        if (!confirm("Do you want to submit your answers?")) return
        axios.post('api/register.php',
          {
            "answer": _this.form,
            "perception": JSON.stringify(_this.perceptionAnswers),
            "edition": _this.editionName()
          })
          .then(function(response){
            console.log("Form sent", response)
            _this.sent = true
            if (_this.editionName() !== "none") _this.$router.go()
          })
          .catch(function(error){
            console.log(error)
          })
      },
      formValidated() {
        // return true
        if (this.partida) {
          if (!this.form.name || !this.excel)
            return false}
        if (this.error){
          if(!this.form.name || !this.form.error)
            return false
        }
        return true
      },
      uploadExcel(){
        return new Promise((res, rej) =>{
          let data = new FormData();
          let excelR = this.$refs.excelRef.files[0];
          data.append('excel', excelR);
          axios.post("api/uploadExcel.php", data, {
            headers: {
              'Content-Type': 'multipart/form-data'
          }
        })
            .then((response) => {
              console.log(response);
              res(response.data)
            })
            .catch((error) => {
              console.log(error);
              rej(error)
          });
        });
      },
      uploadImage(){
        return new Promise((res, rej) =>{
          let data = new FormData();
          //console.log("refs", this.$refs)
          let imageR = this.$refs.imageRef.files[0];
          data.append('image', imageR);
          axios.post("api/uploadImage.php", data, {
            headers: {
              'Content-Type': 'multipart/form-data'
          }
        })
            .then((response) => {
              console.log(response);
              res(response.data)
            })
            .catch((error) => {
              console.log(error);
              rej(error)
          });
        });
      },
      editionName(){
        if (window.location.host.includes("sfl")) return "sfl"
        if (window.location.host.includes("spg")) return "spg"
        return "none"
      },
      editionNameFull(){
        if (window.location.host.includes("sfl")) return "Sant Feliu de Llobregat"
        if (window.location.host.includes("spg")) return "Šempeter pri Gorici"
        return "Anonymous"
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: 600px;
  }

  .score {
    text-align: center;
    font-size: 1.2em;
    color: white;
    background-color: var(--blue-forms);
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    padding: 0.5em;
    line-height: 1em;
    border-radius: 50%;
  }
</style>
