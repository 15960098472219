<template lang="pug">
  .registrar.generalBackground
    h3.coolTitle Clasification
    table(class="table table-striped")
      thead
        tr
          th(scope="col") #
          th(scope="col") Name
          th(scope="col") Score
      tbody
        tr(v-for="pos,index in ranking.slice(0,10)")
          th(score="row") {{index+1}}
          td {{pos.name}}
          td {{pos.score}}
</template>

<script>

import axios from 'axios'

export default {
  name: 'Classificacio',
  data: function(){
    return {
      ranking: [],
      position: 0
    }
  },
  mounted: function(){
    let _this = this;
    axios.get('api/index.php?edition='+_this.editionName())
    //axios.get('/api/index.php')
      .then(function (response) {
        console.log(response);
        _this.ranking = response.data
      })
      .catch(function (error) {
        console.log(error);
      })
  },
  methods: {
    editionName(){
      if (window.location.host.includes("sfl")) return "sfl"
      if (window.location.host.includes("spg")) return "spg"
      return "none"
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: 600px;
  }

  .coolTitle {
    text-align: center;
  }

  table {
    text-align: center;
  }

  tbody tr:first-of-type {
    font-size: 2em;
  }

  tbody tr:nth-of-type(2) {
    font-size: 1.7em;
  }

  tbody tr:nth-of-type(3) {
    font-size: 1.3em;
  }

  /* thead tr {
    background-color: #ffffc2;
  } */

  .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
   background-color: #95C8FF;
  }

  /* .table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
   background-color: #ffffc2;
  } */
</style>
