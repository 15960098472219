<template lang="pug">
  b-container(fluid="true")
    b-row.header(align-v="center" align-h="center")
      b-col(cols="1")
        b-dropdown(text="☰" variant="light" v-if="navigate" no-caret)
          b-dropdown-item(href="/registrar") Register your score
          b-dropdown-item(href="/ajuda") Help
      b-col(cols="8")
        h2.mt-4.homeTitle The Edible City Game 2.1
        h3.homeTitle {{editionName}} edition
      b-col(cols="3")
        b-row(align-h="end")
          div(style="white-space: nowrap;")
            b-img.m-3(src="/images/logos.png" height="100px")
            //- b-img.m-3(src="/images/edicitnet.png" height="100px")
</template>

<script>
export default {
  name: 'Header',
  data:function (){
    return {
      navigate: true
    }
  },
  mounted: function(){
    if (this.$route.path !== '/') this.navigate = true
  },
  computed: {
    editionName(){
      if (window.location.host.includes("sfl")) return "Sant Feliu de Llobregat"
      if (window.location.host.includes("spg")) return "Šempeter pri Gorici"
      return "Anonymous"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header {
    background: linear-gradient(to right, var(--blue-forms), white);
  }

  a {
    color:white;
  }
</style>
