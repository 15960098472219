import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

// set auth header
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;

// set API's baseURL
//Axios.defaults.baseURL = 'http://edicityapi.h2793818.stratoserver.net/api/';

//Axios.defaults.baseURL = 'https://edicitnet.icra.cat/api/sfl/';
//Axios.defaults.baseURL = 'http://localhost/api/sfl/';

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
