<template lang="pug">
  .generalBackground
    Header
    b-container.p-5(fluid="true")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.install icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.install) Instal·lar Tygron
          b-collapse.collapsed(id="install")
            p El primer pas per instal·lar Tygron és descarregar el programa clicant
              a(href="https://engine.tygron.com/")  aquí
            b-img.m-3(src="../images/tygron_engine.png" center thumbnail)
            p Al link ja hauria d'aparèixer l'instal·lador adequat per al nostre sistema operatiu. Un cop descarregat, s'instal·la amb un assistent, com qualsevol programa.
            p.more-info
              a(href="https://support.tygron.com/wiki/Install") Més informació
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.start icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.start) Començar una partida
          b-collapse.collapsed(id="start")
            p El primer que cal fer és obrir Tygron i iniciar sessió amb les credencials adequades (si no les recordeu me les podeu demanar a mi o a en Sergio).
            p Llavors cal clicar a "Single user" i després triar entre començar una partida nova clicant a "Start new session" o carregar-ne una de guardada clicant a "Load saved session" i escollint la data de quan vam guardar la partida.
            b-img.m-3(src="../images/start-1.png" thumbnail center height="200")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.control icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.control) Menú de control de la partida i exportar resultats
          b-collapse.collapsed(id="control")
            p Quan estem jugant, es pot obrir el menú de control de la partida prement la tecla ESC del nostre teclat. Això ens obrirà un menú on podem realitzar diverses opcions:
            ul
              li Settings: Permet ajustar el volum i la qualitat dels gràfics.
              li Crèdits: Permet veure els crèdits de la partida
              li Exit: Tanca el joc
              li Save: Permet guardar la partida per poder-la continuar més endavant amb l'opció "Load saved session del menú d'inici".
              li Export history: Se'ns descarrega un excel amb les accions fetes durant la partida. <b>Aquest excel és el quec s'ha de pujar quan es registra una partida.</b>
            b-img.m-3(src="../images/control-session.png" thumbnail center height="200")
      b-row
              b-col(cols="0")
                h5(class="coll-button")
                  b-icon(v-b-toggle.interface icon="chevron-up" size="sm")
              b-col()
                h5(class="coll-button")
                  a(type="button" v-b-toggle.interface) Interfície del joc
                b-collapse.collapsed(id="interface")
                  p La interfície del joc es divideix bàsicamente entre 8 parts:
                  ol
                    li Model 3D de la ciutat al centre de la pantalla on es visualitza la ciutat i les accions que realitzem per modificar-la.
                    li Menú d'accions a l'esquerra de la pantalla. Hi trobem les accions que podem realitzar sobre la ciutat (construir, demolir, desfer accions...).
                    li El caràcter amb el que estem jugant, en el cas de partida d'un sol jugador no és rellevant.
                    li Temps de la partida i l'escenari que visualitzem a dalt a l'esquerre. L'original és el model de ciutat com era al principi de la partida, el planificat és el model amb els canvis que hi hem fet durant la partida.
                    li Indicadors a dalt al centre. Són els indicadors que es calculen per a la nostra ciutat. Clicant sobre un indicador obtenim informació desglossada per barris, així com el que queda per aconseguir l'objectiu plantejat en la partida.
                    li Barra de progrés: És una suma ponderada dels indicadors.
                      b És, en definitiva, la nostra puntuació a la partida, la que hem d'entrar quan registrem la partida.
                    li Menú de navegació a dalt a la dreta ens permet moure'ns pel model de ciutat. També podem utilitzar el ratolí o el teclat
                    li Menú d'informació addicional a la dreta. La part més important són els anomenats "overlays" que són capes d'informació que podem visualitzar sobre el model per obtenir alguna informació específica (com les emissions de NO2, la distància al verd més proper, etc.)
                  b-img.m-3(src="../images/interface.png" thumbnail center height="200")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.budget icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.budget) Pressupost
          b-collapse.collapsed(id="budget")
            p El pressupost és un dels principals factors limitants a l'hora de planificar la nostra ciutat. Si et quedes en números vermells, la puntuació de la partida és zero. L'única opció llavors és desfer algunes de les accions fins a deixar d'estar en números vermells.
            p Clicant a l'indicador de pressupost ("budget") pots veure el detall dels moviments financers.
            b-img.m-3(src="../images/budget.png" thumbnail center)
            p.more-info
              a(href="https://support.tygron.com/wiki/Financial_system") Més informació
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.actions1 icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.actions1) Les accions
          b-collapse.collapsed(id="actions1")
            p Les accions s'escullen al menú d'accions (esquerre) i s'executen directament sobre la ciutat. A continuació es detallen les accions que es poden fer en una partida d'un sol jugador:
            h5 Construir
            p Permet construir en terrenys buits. En partides multijugador cal ser, a més, propietari del terreny on es vol construir. Es poden veure els elements disponibles per construir i la informació relacionada clicant a l'interrogant per obrir aquest&nbsp;
              a(href="https://ediblecitygame.icradev.cat/ca/") panell.
            b-img.action(src="../images/actions/ecs.png" alt="ECS comunitàries")
            b-img.action(src="../images/actions/prof_ecs.png")
            b-img.action(src="../images/actions/park.png")
            b-img.action(src="../images/actions/social-housing.png")

            h5 Millores
            p Permeten millorar un element existent, per exemple, construir un hort en una teulada. Es pot obtenir informació de totes les millores disponibles clicant a l'interrogant i, un cop obert el panell, obrir la pestanya <a href="https://itlab.icra.cat/ecg/upgrades.php">Upgrades.</a>
            b-img.action(src="../images/actions/rooftop.png")
            b-img.action(src="../images/actions/fruit-trees.png")
            b-img.action(src="../images/actions/solar-panels.png")
            b-img.action(src="../images/actions/street.png")
            h5 Revertir
            p Permet desfer accions realitzades durant la partida, al desfer una acció també es recuperen els diners que ha costat.
            b-img.action(src="../images/actions/revert.png")
            h5 Derruir elements
            p Permet eliminar qualsevol element que hi ha en un espai per poder-hi construir un element nou.
            b-img.action(src="../images/actions/demolish.png")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.actions2 icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.actions2) Executar una acció
          b-collapse.collapsed(id="actions2")
            p Un cop escollida l'acció a realitzar, se'ns ressalten els espais de la ciutat on és permès realitzar aquesta acció. Llavors, cal dibuixar sobre la ciutat l'àrea que es vol modificar amb l'acció escollida. Hi ha diferents maneres de dibuixar aquesta àrea:
            ul
              li Utilitzant una graella i seleccionant els requadres.
              li Dibuixant una línia
                b  &#8592; aquesta és l'opció més idònia
              li Dibuixant un cercle
              li Seleccionant parcel·les senceres
            b-img.m-3(src="../images/draw-action.png" thumbnail center)
            p.text-center Modificant el paràmetre de "Widht" es modifica el gruix i per tant la precisió del dibuix.
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.indicators icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.indicators) Indicadors
          b-collapse.collapsed(id="indicators")
            p Cada acció que s'executa té un impacte determinat sobre els indicadors de ciutat. Abans de confirmar l'acció, es pot veure l'impacte que tindrà.
            p Clicant sobre el nom d'un indicador s'obre el panell de l'indicador amb informacions diverses:
            ul
              li Una explicació de què mesura l'indicador
              li La puntuació per cada barri
              li El que falta per arribar a l'objectiu marcat al joc
              li Consells sobre com millorar el valor de l'indicador
            b-img.m-3(src="../images/indicator.png" thumbnail center)
            //p La puntuació total de la partida és la suma ponderada de tots els indicadors. La pots veure clicant a la barra de progrés a dalt a la dreta.
</template>

<script>
import Header from '@/components/header.vue';

export default {
  name: 'Ajuda',
  components: {
    Header
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .b-icon.bi.collapsed {
    transform: rotate(180deg);
  }

  img {
    max-height: 600px;
  }

  .more-info {
    text-align:right;
  }

  .action {
    height: 50px;
    margin-bottom: 1em;
  }
</style>
